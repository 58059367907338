body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.register-success .MuiSnackbarContent-root {
  background-color: #4caf50 !important;
}

.register-error .MuiSnackbarContent-root {
  background-color: #f44336 !important;
}

.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.dropdown-content a,
.dropdown-content span {
  cursor: pointer;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.favIcon {
  cursor: pointer;
  color: green;
}

.club-pagination-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pac-container {
  z-index: 100000;
}

.search-box {
  height: 35px;
}

/* logo place holder */
.profile-user-img {
  width: 125px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
}

.avatar-upload {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 205px;
  margin: auto;
  margin-bottom: 20px;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 40px;
  z-index: 1;
  top: 90px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input+label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #d2d6de;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input+label:after {
  content: "photo_camera";
  font-family: "Material Icons";
  color: #337ab7;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 34px;
  margin: auto;
}

.event-img-div {
  height: 200px;
}

.event-img {
  width: 100% !important;
  height: 200px;
  object-fit: cover;
}

.home-clubs-logo {
  width: 200px;
  height: 150px;
  object-fit: cover;
}

.file-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  outline: 0;
}

.file-upload.file-upload-drag {
  position: relative;
  width: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  transition: border-color .3s;
}

.file-upload.file-upload-drag .doc-upload-wrapper {
  display: table;
  width: 100%;
  padding: 10px 0 0 0;
}

.file-upload.file-upload-drag .file-upload-container {
  display: table-cell;
  vertical-align: middle;
}

.cloud-upload-icon {
  font-size: 2.5rem !important;
}

.doc-upload-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.uploaded-doc-list {
  display: flex;
  flex-direction: row;
}

.clubs-slider-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  margin: auto;
}

.club-product-col {
  flex-grow: 0 !important;
  max-width: 100% !important;
  flex-basis: 100% !important;
}

.club-logo-holder {
  border-radius: 0 !important;
}

.cover-image-wrapper {
  position: relative;
}

.cover-input-container {
  position: absolute;
}

.cover-image-upload {
  margin: 5px;
  width: 100px !important;
  height: 100px !important;
  border-radius: 35%;
  object-fit: contain;
  padding: 15px;
  background: #00000030;
  cursor: pointer;
}

.club-logo-upload {
  margin: 5px;
  width: 50px !important;
  height: 50px !important;
  border-radius: 35%;
  object-fit: contain;
  padding: 5px;
  background: #00000030;
  cursor: pointer;
}

.cover-image-preview {
  height: 450px;
  width: 100%;
  object-fit: cover;
}

/* product, event image upload container css */

.box {
  display: block;
  min-width: 300px;
  height: 300px;
  margin: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}

.upload-options {
  position: relative;
  height: 75px;
  background-color: #f70;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  transition: background-color ease-in-out 150ms;
}

.upload-options:hover {
  background-color: #f80;
}

.upload-options input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-options label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
}

.upload-options label::after {
  content: "add";
  font-family: "Material Icons";
  position: absolute;
  font-size: 2.5rem;
  color: #e6e6e6;
  top: calc(50% - 2.5rem);
  left: calc(50% - 1.25rem);
  z-index: 0;
}

.upload-options label span {
  display: inline-block;
  width: 50%;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
}

.upload-options label span:hover i.material-icons {
  color: lightgray;
}

.js--image-preview {
  height: 225px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.js--image-preview::after {
  content: "photo_size_select_actual";
  font-family: "Material Icons";
  position: relative;
  font-size: 4.5em;
  color: #e6e6e6;
  top: calc(50% - 3rem);
  left: calc(50% - 2.25rem);
  z-index: 0;
}

.js--image-preview.js--no-default::after {
  display: none;
}

i.material-icons {
  transition: color 100ms ease-in-out;
  font-size: 2.25em;
  line-height: 55px;
  color: white;
  display: block;
}

.drop {
  display: block;
  position: absolute;
  background: rgba(95, 158, 160, 0.2);
  border-radius: 100%;
  transform: scale(0);
}

.btn-global, .delete-account-btn {
  border-color: #f50 !important;
  color: #f50 !important;
  margin-bottom: 10px !important;
}

.btn-global:hover {
  background: #f50 !important;
  color: #fff !important;
}

.delete-account-btn:hover {
  background-color: #ff5050 !important;
  border-color: #b72626 !important;
  color: #323131 !important;
  font-weight: bold;
}

.btn-global-filled {
  background-color: #f50 !important;
  color: #fff !important;
}

.btn-global-filled:hover {
  background-color: #f40 !important;
  color: #fff !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #f50 !important;
}

.animate {
  -webkit-animation: ripple 0.4s linear;
  animation: ripple 0.4s linear;
}

.event-carousel-wrapper .react-multi-carousel-track {
  transform: translate3d(60px, 0px, 0px);
  transform-style: flat;
}

.sponsor-item-default-margin {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.payment-page-titles {
  font-family: "Raleway";
  font-weight: bold;
  font-size: 3.125rem;
  text-transform: uppercase;
  line-height: 1.4;
  background: #880065;
  background: linear-gradient(90deg, #009933 0%, #0099ff 100%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
  margin-bottom: 0.313rem;
}

.payment-sub-heading {
  font-size: 1.20rem;
  font-weight: 550;
}

.payment-sub-heading span {
  color: #f50;
}

.pay-btn {
  margin-top: 20px !important;
  color: white !important;
  background-color: #f80 !important;
}

.pay-btn:hover {
  color: white !important;
  background-color: #f50 !important;
}

.event-desc {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
#payment-box {
padding: 50px 15px !important;
}

#payment-box form {
margin-top: 30px;
}

.payment-method {
margin-bottom: 0px !important;
margin-top: 15px !important;
}

.payment-method-box {
margin-bottom: 20px !important;
}

.pay-btn span {
min-height: 40px;
}

}

@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

.btn-tertiary {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  color: #f50 !important;
}

.btn-tertiary span:first-child {
  order: 0 !important;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: none;
  line-height: 16px;
  border-bottom: 1px solid orange !important;
  padding-bottom: 0;
}

.btn-dangerous {
  background: #f74e4e !important;
}

.btn-dangerous:hover {
  background: #f42929 !important;
}