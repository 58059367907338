/*# sourceMappingURL=bootstrap.min.css.map */


/* @import url("https://fonts.googleapis.com/css?family=Cabin:400,400i,500,600"); */
/**
*** Table Of Contents
*
* Bootstrap Overrides
  - columns
  - container
  - breadcrumbs
  - pagination
  - forms
  - carousel
  - accordion
  - progressbar
  - navs and tabs
  - tables
  - etc.
* General Styles for HTML tags
* Styled Lists
* Buttons
* JQuery Plugins
  - Owl Carousel
  - FlexSlider
  - prettyPhoto
  - Easy pieChart
  - Time Table
  - toTop
  - Countdown
* Vertical Margins and Paddings
* Common Sections Styles
  - parallax
  - page preloader
  - animated elements
  - boxed layout
* Helper Utilites
  - layout
  - borders, colors and backgrouns
  - dividers, margins and paddings
  - alignment
  - media items
* Header
* Side Header
  - side header layout
  - side menu styles
  - sidebar scroll plugin
* Light Section - .ls
* Grey Section - .ls.ms
* Dark Sections - .ds
* Color Section - .cs
* Template Specific Styles
  - topline styles
  - toplogo styles
  - title section (breadcrumbs) styles
  - template parallax sections
  - footer styles
  - copyrights styles
  - google map
  - intro slider inside content
* Intro Section Styles
* Main Menu
  - Desktop menu
  - Mobile menu
  - Mobile menu toggler
  - Mega Menu
* Subpages
  - 404
  - about
  - blog
  - single post
  - gallery
  - post formats
* Widgets
  - common widgets styles
  - search_mailchimp
  - nav_recent_categories
  - tags
  - popular
  - recent_posts
  - calendar
  - banner
  - slider
  - flickr
  - instagram
  - twitter
  - post_tabs
  - rss
  - access_press
* Shortcodes
  - teasers
  - testimonials
  - thumbnail
  - price tables
  - contact forms
  - partners_carousel
* Social Icons
  - layout
  - corporate colors
*/
/*
** Template colors
*

Accent Colors:
1: #ff5500
2: #f0cd45

Light section (.ls) colors:
Text color in light section: #818181
Background color for light section and font color in dark section: #ffffff
Color for headings in light section and for background in dark section (.ds): #323232
Background color for light muted section (grey - .ls.ms): #f5f5f5
Background color for dark muted section (.ds.ms): #2a2a2a
Border colors for elements in light section (and .with-border elements): #e6e6e6, rgba(0, 0, 0, 0.1)
Background color for elements in light section (.with-background): #f5f5f5

Dark section (.ds) colors:
Text color in dark section: #b1b1b1
Border colors for elements in dark section: rgba(152, 152, 152, 0.2)
Background color for elements in dark section (.with-background): rgba(255, 255, 255, 0.05)

Color section (.cs) colors:
Border colors for elements in color section: rgba(255, 255, 255, 0.2)

*/
/*
** Bootstrap Overrides
*/
/* columns with 20 and 40 percents width */
.col-xs-20,
.col-sm-20,
.col-md-20,
.col-lg-20 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-20 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-20 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-20 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-20 {
    width: 20%;
    float: left;
  }
}

.col-xs-40,
.col-sm-40,
.col-md-40,
.col-lg-40 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-40 {
  width: 40%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-40 {
    width: 40%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-40 {
    width: 40%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-40 {
    width: 40%;
    float: left;
  }
}

/* half on .container in .container-fluid */
.container-left-half,
.container-right-half {
  padding-right: 15px;
  padding-left: 15px;
}

.container-left-half {
  margin-right: 0;
  margin-left: auto;
}

.container-right-half {
  margin-right: auto;
  margin-left: 0;
}

@media (min-width: 768px) {
  .container-left-half,
  .container-right-half {
    width: 375px;
  }
}

@media (min-width: 992px) {
  .container-left-half,
  .container-right-half {
    width: 485px;
  }
}

@media (min-width: 1200px) {
  .container-left-half,
  .container-right-half {
    width: 585px;
  }
}

.profile-header {
  height: 149px;
}

@media (max-width: 768px) {
  .profile-header {
    height: 136px;
  }
}

.profile-header .header-section {
  background: url("../img/header-bg.jpg");
  background-position: bottom;
  background-size: cover;
}

/* bootstrap breadcrumbs*/

/* pagination */


/* forms */


/* select styling */

/* select styling */


/*icons for forms */

/* carousel */
/* collapse accordion */

/* progress bars */

/* nav and tabs */
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
}

.nav-unstyled {
  list-style: none;
  padding: 0;
  margin: 0 -12px;
}

.nav-unstyled li {
  display: inline-block;
  margin-bottom: -1px;
}

.nav-unstyled li a {
  display: inline-block;
  padding: 10px 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.nav-unstyled .active a {
  color: #323232;
  border-color: #ff5500;
}

/*tabs*/
.tab-content {
  padding: 30px 20px;
  border: none;
  background-color: #f5f5f5;
}

.tab-content.no-border {
  padding: 30px 0 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.tab-content.top-color-border {
  position: relative;
}

.tab-content.top-color-border:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 4px;
  background-color: #ff5500;
}

.tab-content.tab-unstyled {
  padding: 10px 0 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.ls .tab-content.tab-unstyled,
.ds .tab-content.tab-unstyled,
.cs .tab-content.tab-unstyled {
  background-color: transparent;
}

.tab-content .featured-tab-image {
  margin: -30px -20px 30px;
}

@media (min-width: 400px) {
  .tab-content .featured-tab-image {
    margin: -35px -35px 30px;
  }
}

@media (min-width: 1200px) {
  .tab-content .featured-tab-image {
    margin: -55px -55px 30px;
  }
}

@media (min-width: 400px) {
  .tab-content {
    padding: 35px;
  }
}

@media (min-width: 400px) {
  aside .tab-content {
    padding: 30px 20px;
  }
}

@media (min-width: 1200px) {
  .tab-content {
    padding: 55px;
  }
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  border-bottom: none;
}

.pp-tab-link:hover {
  color: #fff !important;
}

.nav-tabs {
  position: relative;
  border-bottom: none;
  margin-top: 0px;
  z-index: 1;
}

.nav-tabs > li {
  margin-bottom: 0;
}

.nav-tabs > li + li {
  margin-left: 3px;
}

.nav-tabs > li > a {
  position: relative;
  z-index: 1;
  font-size: 10px;
  text-transform: uppercase;
  padding: 10px;
  margin-right: 0;
  margin-top: 0px;
  background-color: #f5f5f5;
  border: none;
  color: #818181;
  border-radius: 5px 5px 0 0;
}

.small-tabs .nav-tabs > li > a {
  padding: 10px;
}

.nav-tabs > li > a i {
  position: relative;
  top: 4px;
  padding-right: 5px;
  font-size: 1.6em;
  width: 1.5em;
  text-align: center;
  display: inline-block;
  line-height: 0;
}

@media (min-width: 992px) {
  .nav-tabs > li > a {
    padding: 12px 28px;
    font-size: 12px;
    letter-spacing: 0.2em;
  }
}

@media (min-width: 1200px) {
  .nav-tabs > li > a {
    padding: 21px 45px;
  }
}

@media (max-width: 400px) {
  .nav-tabs > li > a {
    font-size: 10px;
    padding: 8px;
  }

  .small-tabs .nav-tabs > li > a {
    font-size: 10px;
    padding: 8px;
  }
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background-color: #ff5500;
  color: #ffffff;
  border-width: 0;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus,
.nav-tabs.nav-justified > .active > a {
  background-color: #ff5500;
  color: #ffffff;
}

/*vertical tabs*/
.vertical-tabs .tab-content.no-border {
  padding-top: 0;
}

.vertical-tabs .nav > li > a {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  background-color: #f5f5f5;
  padding: 18px 40px 18px 30px;
  margin-bottom: 10px;
  position: relative;
  border-radius: 5px;
  color: #818181;
}

.vertical-tabs .nav > li > a i {
  position: relative;
  top: 4px;
  padding-right: 8px;
  font-size: 1.6em;
  width: 2em;
  text-align: center;
  display: inline-block;
  line-height: 0;
}

.vertical-tabs .nav > li > a:after {
  font-family: 'rt-icons-2';
  content: "\e7c5";
  position: absolute;
  font-size: 12px;
  line-height: 60px;
  text-align: center;
  right: 0;
  top: 0;
  bottom: 0;
  width: 46px;
  letter-spacing: 0;
}

.vertical-tabs.no-arrows .nav > li > a:after {
  display: none;
}

.vertical-tabs.no-arrows .nav > li > a {
  padding: 24px 0;
}

.vertical-tabs .nav > li.active a,
.vertical-tabs .nav > li > a:hover {
  background-color: #ff5500;
  box-shadow: none;
  color: #ffffff;
}

@media (min-width: 768px) {
  .vertical-tabs .nav > li.active {
    position: relative;
    z-index: 2;
  }
}

/* modal */

/* bootstrap tables */


/* bootstrap buttons */


/* bootstrap dropdown menu */

/* small in headings */


/* labels */

/*caret*/


/*
** General Styles for HTML tags
*/


/* p {
	margin-bottom: 15px;
}

p:last-child {
	margin-bottom: 0;
} */

@media (min-width: 992px) {
  .columns-2 .product {
    width: 50%;
    float: left;
  }

  .columns-2 .product:nth-child(2n + 1) {
    clear: both;
  }
}

li.product {
  text-align: center;
}

li.product {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}

.single-product-img {
  max-width: 100%;
  height: auto;
}

.product-tab {
  display: inline-block !important;
}

.profile-image-box {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.profile-contact-div {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.profile-contact-details {
  margin-right: 10px;
  font-weight: 400;
}

.profile-contact-details p {
  margin-bottom: 0px !important;
}


.profile-contact-details.address div, .profile-contact-details.email, .profile-contact-details.address p, .profile-contact-details p {
  margin-left: 30px;
}

.profile-contact-details.email {
  display: block;
  margin-bottom: 20px;
}

.fav-button {
  cursor: pointer;
  margin-top: 20px;
  display: block;
}


.product-create-btn {
  /* float: right; */
  margin: 0 0 20px 0;
}

.club-status {
  font-size: 18px;
  color: red;
  font-weight: 600;
}

.event-desc {
  text-align: left !important;
  max-width: 240px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: auto;
  overflow: hidden;
}

.item-size {
  min-width: 320px;
}

.event-details {
  text-align: left !important;
  display: grid;
}

.event-details span {
  margin-bottom: 5px;
}

.tab-th {
  margin-top: 19px;
}

.cover-img {
  width: 100%;

  height: 0px;
}

.cover-img img {
  width: 100%;
  height: 350px;
}

.upload-container {
  width: 100%;
}

.club-desc {
  margin-top: 245px;
}

.profile-img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.club-public-profile-silder {
  background: none !important;
}

.club-public-profile-silder .overlay {
  padding: 0 !important;
}

.club-item, .sign-out-btn {
  cursor: pointer;
}

.public-profile-divider {
  padding-top: 25px;
  padding-bottom: 25px;
}

.public-profile-sponsor-list {
  display: block;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
  margin: 5px 0 5px 0;
}

.public-profile-sponsor-list .product-title {
  font-weight: 600 !important;
  font-size: 1.25rem !important;
}

.public-profile-sponsor-list .product-button {
  border-color: #f50 !important;
}

.public-profile-sponsor-list .product-button:hover {
  background-color: #f50 !important;
}


.public-profile-sponsor-list .product-button span {
  color: #f50 !important;
}

.public-profile-sponsor-list .product-button:hover > span {
  color: #fff !important;
}

.sponsor-list-action {
  float: right;
}

.sponsor-list-content {
  float: left;
  width: 75%;
}

figcaption, .entry-caption {
  padding: 2px 0 12px;
}

figcaption p, .entry-caption p {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  transition: all 0.2s ease-in-out 0s;
}

a:hover {
  color: #f0cd45;
  text-decoration: none;
  transition: all 0.2s linear 0s;
}

input:focus,
button:focus,
select:focus,
textarea:focus,
a:focus {
  outline: medium none;
  text-decoration: none;
}

a > img {
  transition: all 0.2s ease-in-out 0s;
}

a:hover > img {
  opacity: 0.8;
}

hr {
  border: none;
  height: 1px;
  margin-left: 0;
  margin-right: 0;
  background-color: #ddd;
}

iframe {
  border: none;
  max-width: 100%;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

table {
  width: 100%;
  max-width: 100%;
  margin: 10px 0;
}

table td, table th {
  padding: 8px 10px;
  line-height: 1.42857143;
  vertical-align: top;
  border: 1px solid #e6e6e6;
}

table th {
  color: #323232;
  font-weight: normal;
  vertical-align: middle;
  line-height: 1;
}

.table_template thead {
  background-color: #ff5500;
  color: #323232;
}

.table_template thead.light {
  background-color: #ffffff;
}

.table_template th {
  font-weight: 700;
}

.table_template td, .table_template th {
  padding: 18px 30px;
}

.table_template tbody tr:nth-of-type(odd) {
  background-color: rgba(238, 238, 238, 0.1);
}

.table_template tbody tr:nth-of-type(even) {
  background-color: rgba(17, 17, 17, 0.05);
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/*
** Styled Lists
*/
ul, ol {
  padding-left: 30px;
  margin-bottom: 20px;
}

.list1 ul,
ul.list1 {
  list-style: none;
  padding: 0;
}

.padding_30 .list1 ul, .padding_30
ul.list1 {
  margin: 0;
}

.list1 li {
  position: relative;
  padding: 9px 0 10px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.muted_background .list1 li {
  border-color: rgba(255, 255, 255, 0.4);
}

.list1 li > .media {
  margin: 3px 0;
}

@media (min-width: 992px) {
  .list1 li {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.list1.big-padding li {
  padding-top: 22px;
  padding-bottom: 21px;
}

.list1.huge-padding li {
  padding-top: 37px;
  padding-bottom: 36px;
}

.list1.no-bullets li {
  padding-left: 0;
}

.list1.no-bullets li:before {
  display: none;
}

.list1 li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.list1.no-top-border li:first-child {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

.list1.no-bottom-border li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.list1 li:before {
  content: '';
  display: block;
  position: absolute;
  border: 1px solid #ff5500;
  left: 10px;
  top: 18px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.list2 ul,
ul.list2 {
  list-style: none;
  padding-left: 20px;
}

.list2 li {
  position: relative;
  padding: 7px 0 7px 0;
}

.list2 li:first-child {
  padding-top: 0;
}

.list2 li:first-child:before {
  top: calc(50% - 7px);
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.list2 li:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #ff5500;
  border-radius: 50%;
  left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #ff5500;
}

.list2.no-bullets {
  padding-left: 0 !important;
}

.list2.no-bullets li:before {
  display: none;
}

.list2.checklist {
  padding-left: 30px;
}

.list2.checklist li:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 14px;
  left: -30px;
  color: #ff5500;
  width: auto;
  height: auto;
  background-color: transparent;
}

.list2.checklist li:first-child:before {
  margin-top: -8px;
}

.list2.checklist.color2 li:before {
  color: #f0cd45;
}

.list2.checklist.color3 li:before {
  color: #007ebd;
}

.list3 ol,
ol.list3 {
  counter-reset: li;
  list-style: none outside none;
  padding: 0;
}

.list3 li {
  position: relative;
  padding: 6px 0 6px 25px;
}

.list3 li.active:after {
  color: #f0cd45;
}

.list3 li:after {
  content: counter(li) ".";
  counter-increment: li;
  position: absolute;
  left: 2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-weight: 600;
  color: #ff5500;
}

.list3.color li:after {
  color: #ff5500;
}

.list3.color2 li:after {
  color: #f0cd45;
}

.list-unstyled .media {
  margin-bottom: 10px;
}

.list-unstyled li:last-child > .media {
  margin-bottom: 0;
}

.list-unstyled .media-left {
  padding-right: 10px;
}

.list-unstyled .media-left img {
  border-radius: 50%;
}

.list-unstyled .media-right {
  padding-left: 10px;
}

.list-unstyled .media-right img {
  border-radius: 50%;
}

.list-unstyled .media-body .pull-right {
  margin: 0 20px;
}

.inline-list {
  list-style: none;
  margin: 0 -8px;
  padding: 0;
}

.inline-list > li {
  display: inline-block;
  padding: 0 8px;
}

@media (min-width: 1200px) {
  .inline-list {
    margin: 0 -15px;
  }

  .inline-list > li {
    padding: 0 17px;
  }

  .inline-list.big-padding > li {
    padding: 0 25px;
  }
}

.inline-list.menu-style-links > li {
  padding-top: 5px;
  padding-bottom: 5px;
}

/*
** Buttons
*/
/* .theme-buttons a,
.theme-button,
.wc-proceed-to-checkout .checkout-button,
.buttons a,
button,
input[type="submit"] {
	position: relative;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: 700;
	padding: 24px 45px;
	margin-bottom: 20px;
	margin-right: 16px;
	line-height: 1;
	display: inline-block;
	text-align: center;
	color: #ff5500;
	background-color: #323232;
	border-radius: 30px;
	border: none;
	position: relative;
	transition: all 0.4s linear 0s;
	z-index: 1;
} */

.theme-buttons a:last-child,
.theme-button:last-child,
.wc-proceed-to-checkout .checkout-button:last-child,
.buttons a:last-child,
button:last-child,
input[type="submit"]:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

.theme-button[type="reset"] {
  display: none;
}

.theme-buttons.color1 a,
.theme-button.color1,
.button.alt,
.btn-accent,
.wc-proceed-to-checkout .checkout-button,
.form-submit [type="submit"] {
  color: #ffffff;
  background-color: #ff5500;
}

.theme-buttons.color1 a:hover, .theme-buttons.color1 a:focus, .theme-buttons.color1 a:active,
.theme-button.color1:hover,
.theme-button.color1:focus,
.theme-button.color1:active,
.button.alt:hover,
.button.alt:focus,
.button.alt:active,
.btn-accent:hover,
.btn-accent:focus,
.btn-accent:active,
.wc-proceed-to-checkout .checkout-button:hover,
.wc-proceed-to-checkout .checkout-button:focus,
.wc-proceed-to-checkout .checkout-button:active,
.form-submit [type="submit"]:hover,
.form-submit [type="submit"]:focus,
.form-submit [type="submit"]:active {
  color: #323232;
}

.theme-buttons.inverse a,
.theme-button.inverse {
  background-color: #e6e6e6;
}

.theme-buttons.inverse a:hover, .theme-buttons.inverse a:focus, .theme-buttons.inverse a:active,
.theme-button.inverse:hover,
.theme-button.inverse:focus,
.theme-button.inverse:active {
  color: #323232;
}

.with-background .theme-buttons.inverse a, .with-background
.theme-button.inverse {
  background-color: #ffffff;
}

.theme-buttons a:active,
.theme-buttons a:hover,
.theme-buttons a:focus,
.theme-button:active,
.theme-button:hover,
.theme-button:focus,
.wc-proceed-to-checkout .checkout-button:hover {
  color: #ff5500;
  opacity: 1;
  text-decoration: none;
  outline: none;
  transition: all 0.05s linear 0s;
}

.theme-buttons a:active,
.theme-button:active {
  top: 1px;
}

.muted_buttons a,
.muted_button {
  opacity: 0.2;
}

.small_buttons a,
.small_button {
  padding: 7px 10px;
  text-transform: uppercase;
  letter-spacing: 0;
}

.square_buttons a,
.square_button {
  padding: 0;
  width: 50px;
  line-height: 50px;
  margin: 0;
}

.round_buttons a,
.round_button {
  padding: 0;
  width: 60px;
  line-height: 60px;
  border-radius: 30px;
}

.round_buttons a i,
.round_button i {
  font-size: 1.2em;
  top: 2px;
  left: 2px;
}

.wide_buttons a,
.wide_button {
  padding-right: 60px;
  padding-left: 60px;
}

a.inactive {
  pointer-events: none;
}

.block_buttons a,
.block_button {
  display: block;
  width: 100%;
  text-align: center;
}

.min-width-button {
  min-width: 160px;
}

@media (min-width: 1200px) {
  .min-width-button {
    min-width: 200px;
  }
}

.no_bg_button {
  background-color: transparent !important;
  border-color: transparent;
}

.no_bg_button.color1 {
  color: #ff5500;
}

.no_bg_button.color1:hover, .no_bg_button.color1:focus, .no_bg_button.color1:active {
  color: #323232;
}

/*icons in headings, paragraphs and buttons*/
h1 > [class*="rt-icon"],
h2 > [class*="rt-icon"],
h3 > [class*="rt-icon"],
h4 > [class*="rt-icon"],
h5 > [class*="rt-icon"],
h6 > [class*="rt-icon"] {
  position: relative;
  top: .08em;
  padding-right: 0.1em;
}

p > i {
  font-size: 1.1em;
  line-height: 0;
}

p > i + [class*="rt-icon"] {
  margin-left: -0.75em;
}

.theme-buttons a > [class*="rt-icon"],
.theme-button > [class*="rt-icon"] {
  font-size: 1.5em;
  line-height: 0;
  position: relative;
  top: 3px;
  padding: 0 3px;
}

.theme-buttons a > [class*="glyphicon"],
.theme-buttons a > [class*="fa-"],
.theme-button > [class*="glyphicon"],
.theme-button > [class*="fa-"] {
  font-size: 1.5em;
  position: relative;
  top: 3px;
  line-height: 0;
}

.round_buttons a > i.fa,
.round_button > i.fa {
  font-size: 1.2em;
  top: 1px;
  left: 2px;
}

.form-inline [type="submit"],
.form-inline .btn,
.form-inline .theme-button {
  margin-bottom: 0;
}

.btn-group-lg > .btn, .btn-lg {
  padding: 16px 16px;
}

.btn-group-sm > .btn, .btn-sm {
  padding: 5px 10px;
}

.btn-group-xs > .btn, .btn-xs {
  padding: 1px 5px;
}

.more-link {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.more-link:after {
  content: "\f149";
  font-family: FontAwesome;
  padding-left: 4px;
}

/*
**plugins
*/
/* Owl carousel v 2.1.6 styles */
/* Owl Carousel - Core */

.ds .owl-carousel .owl-dots .owl-dot span,
.cs .owl-carousel .owl-dots .owl-dot span {
  border-color: #ffffff;
}


.background_cover .owl-carousel .owl-dots .owl-dot span {
  border-color: #ffffff;
}

.background_cover .owl-carousel .owl-dots .owl-dot.active span, .background_cover .owl-carousel .owl-dots .owl-dot:hover span {
  border-color: #ffffff;
  background-color: #ffffff;
}


.cs .owl-carousel .owl-nav > div:after {
  color: #ffffff;
}

.top-right-nav .owl-nav {
  position: absolute;
  top: -70px;
  right: 0;
}

.top-right-nav .owl-nav > div {
  background: transparent;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* Owl Carousel - Animate Plugin */


@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Owl Carousel - Auto Height Plugin */
.owl-height {
  transition: height 500ms ease-in-out;
}

/* Owl Carousel - Lazy Load Plugin */

/* Owl Carousel - Video Plugin */

/* Owl Default Theme */

@media (min-width: 1300px) {
  .container .col-sm-12 .owl-theme .owl-nav {
    left: -60px;
    right: -60px;
  }
}

@media (min-width: 1600px) {
  .container .col-sm-12 .owl-theme .owl-nav {
    left: -150px;
    right: -150px;
  }
}

.owl-theme .owl-dots {
  margin-top: 40px;
}

/* .owl-center theme */
.owl-center .owl-nav {
  top: 0;
  bottom: 0;
  position: static;
  margin-top: 0;
  opacity: 1;
}

.owl-center .owl-nav > div {
  right: 0;
  width: 30px;
  top: 50%;
  bottom: 0;
  margin: 0;
  height: 80px;
  text-align: center;
  opacity: 1;
  background-color: rgba(50, 50, 50, 0.6);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.owl-center .owl-nav > div:after {
  position: absolute;
  top: 50%;
  color: #ffffff;
  font-size: 20px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.owl-center-scale.owl-carousel {
  margin-bottom: -30px;
}

.owl-center-scale.owl-carousel .owl-stage-outer {
  padding-top: 30px;
  padding-bottom: 30px;
}

.owl-center-scale.owl-carousel .owl-item > * > * {
  transition: all 0.2s ease 0s;
}

.owl-center-scale.owl-carousel .center {
  z-index: 2;
}

.owl-center-scale.owl-carousel .center > * > * {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  overflow: hidden;
}

/* owl images fix */
.owl-carousel .owl-item {
  transition: opacity 0.2s ease 0.1s;
  opacity: 0;
}

.owl-carousel .owl-item.active {
  opacity: 1;
}

.owl-center .owl-item {
  opacity: 1;
}

.with_shadow_items {
  padding: 20px;
  margin: -20px;
  width: calc(100% + 40px);
  overflow: hidden;
}

.with_shadow_items .owl-stage-outer {
  overflow: visible;
}

/* Text nav */
.owl-carousel.text-nav {
  padding-top: 60px;
}

.owl-carousel.text-nav .owl-nav {
  top: 0;
}

.owl-carousel.text-nav .owl-nav:before {
  content: "";
  width: 2px;
  height: 11px;
  position: absolute;
  background-color: #818181;
  top: 9px;
  left: calc(50% - 2px);
}

.owl-carousel.text-nav .owl-nav > div {
  position: static;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #818181;
  width: auto;
  padding: 5px 30px;
}

.owl-carousel.text-nav .owl-nav > div:hover {
  color: #ff5500;
}

.owl-carousel.text-nav .owl-nav > div:after {
  display: none;
}

.owl-carousel.text-nav .owl-nav > div.disabled {
  pointer-events: none;
}

.profile-box {
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  background: rgb(245, 245, 245);
  background: -moz-linear-gradient(0deg, rgb(245, 245, 245) 0%, rgba(245, 245, 245, 0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%);
  background: linear-gradient(0deg, rgb(245, 245, 245) 0%, rgba(245, 245, 245, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5f5", endColorstr="#f5f5f5", GradientType=1);
}

.profile-club-near {
  min-height: auto !important;
}

/*
** Flexslider
* http://www.woothemes.com/flexslider/
*
* Copyright 2012 WooThemes
* Free to use under the GPLv2 license.
* http://www.gnu.org/licenses/gpl-2.0.html
*
* Contributing author: Tyler Smith (@mbmufffin)
*/
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* FlexSlider Necessary Styles */
.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
  width: 100%;
  display: block;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
* include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block;
}

.flexslider .slides > li {
  position: relative;
}

/* FlexSlider Default Theme */
.flexslider {
  margin: 0 0;
  position: relative;
  zoom: 1;
}

.flex-viewport {
  max-height: 2000px;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.flexslider .slides {
  zoom: 1;
  z-index: 1;
}

/* Direction Nav */
.flex-direction-nav {
  *height: 0;
}

.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 60px;
  height: 60px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  font-size: 0;
  color: #323232;
  background-color: transparent;
  text-align: center;
}

.flex-direction-nav a:hover, .flex-direction-nav a:active, .flex-direction-nav a:focus {
  color: #ff5500;
}

.ds .flex-direction-nav a {
  color: #ffffff;
}

.ds .flex-direction-nav a:hover {
  color: #ff5500;
}

.flex-direction-nav .flex-prev {
  left: 0px;
}

.flex-direction-nav .flex-next {
  right: 0px;
}

@media (min-width: 1450px) {
  .flex-direction-nav .flex-prev {
    left: 60px;
  }

  .flex-direction-nav .flex-next {
    right: 60px;
  }
}

@media (min-width: 1800px) {
  .flex-direction-nav .flex-prev {
    left: 240px;
  }

  .flex-direction-nav .flex-next {
    right: 240px;
  }
}

.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
  opacity: 1;
}

.flex-direction-nav .flex-disabled {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: default;
}

.flex-direction-nav a:before {
  font-family: "FontAwesome";
  font-size: 36px;
  line-height: 56px;
  content: '\f104';
}

.flex-direction-nav a.flex-next:before {
  content: '\f105';
}

/* Pause/Play */
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}

.flex-pauseplay a:before {
  font-family: 'rt-icons-2';
  font-size: 20px;
  display: inline-block;
  content: "\e6a2";
}

.flex-pauseplay a:hover {
  opacity: 1;
}

.flex-pauseplay a.flex-play:before {
  content: "\e711";
}

/* Control Nav */
.flex-control-nav {
  left: 0;
  right: 0;
  position: absolute;
  bottom: 25px;
  text-align: center;
  z-index: 10;
}

@media (min-width: 992px) {
  .flex-control-nav {
    bottom: 35px;
  }
}

.flex-control-nav li {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
  zoom: 1;
  *display: inline;
}

.flex-control-nav li:first-child {
  padding-left: 0;
}

.flex-control-nav li:last-child {
  padding-right: 0;
}

.flex-control-paging li a {
  width: 12px;
  height: 12px;
  display: block;
  border: 1px solid #818181;
  background-color: transparent;
  color: transparent;
  cursor: pointer;
  text-indent: -9999px;
  border-radius: 50%;
}

.ds .flex-control-paging li a,
.cs .flex-control-paging li a {
  border-color: #ffffff;
}

.flex-control-paging li a:hover,
.flex-control-paging li a.flex-active {
  background-color: #818181;
}

.cs .flex-control-paging li a:hover, .cs .flex-control-paging li a.flex-active,
.ds .flex-control-paging li a:hover,
.ds .flex-control-paging li a.flex-active {
  background-color: #ffffff;
}

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}

.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: .7;
  cursor: pointer;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

@media screen and (max-width: 600px) {
  .flex-direction-nav .flex-prev {
    top: 24%;
  }

  .flex-direction-nav .flex-next {
    top: 24%;
  }
}

/*
** Easy pieChart
*/
.chart {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  text-align: center;
  line-height: 0;
}

.chart .chart-meta {
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.chart .chart-meta h4 {
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  color: #818181;
}

.percent {
  display: inline-block;
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
  z-index: 2;
}

.percent:after {
  content: '%';
  font-size: 35px;
}

.angular {
  margin-top: 100px;
}

.angular .chart {
  margin-top: 0;
}

/*
** Time Table
*/
#timetable span {
  display: block;
}

#timetable th, #timetable td {
  vertical-align: middle;
  font-weight: normal;
  text-align: center;
  padding: 25px 5px;
}

#timetable thead {
  color: #ffffff;
}

#timetable thead th {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-width: 0 1px;
}

#timetable tbody th, #timetable tbody td {
  border: 1px solid #e6e6e6;
  transition: all 0.2s ease-in-out 0s;
}

#timetable tbody .current {
  background-color: #ff5500;
  border-color: #ff5500;
}

#timetable tbody .current a {
  color: #ffffff;
}

/*
** toTop
*/
#toTop {
  bottom: 10px;
  right: 10px;
  display: none;
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: fixed;
  text-decoration: none;
  z-index: 9999;
  font-size: 0;
  color: transparent;
  color: #ffffff;
  background: #ff5500;
  border-radius: 25px;
}

@media (min-width: 768px) {
  #toTop {
    bottom: 30px;
    right: 30px;
  }
}

@media (max-width: 768px) {
  .club-desc {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@media (min-width: 992px) {
  #toTop {
    width: 60px;
    height: 60px;
    bottom: 60px;
    right: 60px;
    border-radius: 30px;
  }
}

#toTop:after {
  color: #ffffff;
  content: "\f106";
  font-size: 16px;
  line-height: 50px;
  font-family: FontAwesome;
  text-align: center;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.2s linear;
}

@media (min-width: 992px) {
  #toTop:after {
    line-height: 60px;
  }
}

#toTopHover, #toTop:hover {
  bottom: 15px;
  opacity: 1;
}

@media (min-width: 768px) {
  #toTopHover, #toTop:hover {
    bottom: 35px;
  }
}

@media (min-width: 992px) {
  #toTopHover, #toTop:hover {
    bottom: 65px;
  }
}

#toTop:hover:after {
  color: #ffffff;
}

#toTop:active, #toTop:focus {
  outline: medium none;
}

.modal-open #toTop {
  visibility: hidden;
}

/* Countdown */
#comingsoon-countdown {
  text-align: center;
}

#comingsoon-countdown:before,
#comingsoon-countdown:after {
  clear: both;
  display: table;
  content: ' ';
}

.countdown-rtl {
  direction: rtl;
}

.countdown-row {
  clear: both;
  display: table;
  margin: auto;
  padding: 20px 0;
  text-align: center;
}

.countdown-section {
  display: table-cell;
  padding: 10px 30px 20px;
  text-align: center;
  border-left: 1px solid #e6e6e6;
}

.countdown-section:first-child {
  border-left-width: 0;
}

.countdown-amount {
  font-size: 80px;
  line-height: 1;
  font-weight: 700;
  color: #ff5500;
}

.countdown-period {
  display: block;
  color: #323232;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 700;
}

.countdown-descr {
  display: block;
}

@media (max-width: 767px) {
  .countdown-section {
    padding: 20px 8px 0;
  }

  .countdown-amount {
    font-size: 40px;
  }

  .countdown-period {
    min-width: 4em;
    letter-spacing: 0.1em;
  }
}

/* cue player */
.mejs-audiotheme-mark {
  display: none !important;
}

.cue-playlist {
  font-family: 'Cabin', sans-serif;
}

.cue-skin-default.mejs-container .mejs-player-background {
  top: -10px;
  left: -10px;
  bottom: 0;
  right: -10px;
  min-height: calc(100% + 20px);
  max-width: calc(100% + 20px);
  width: calc(100% + 20px);
  opacity: 0.6;
}

.cue-skin-default.mejs-container .mejs-controls .mejs-time-rail {
  box-shadow: none;
}

.cue-skin-default.mejs-container .mejs-controls .mejs-time-rail .mejs-time-total {
  background: rgba(255, 255, 255, 0.1);
}

.cue-skin-default.mejs-container .mejs-inner {
  padding: 20px 20px 25px;
}

.cue-skin-default.mejs-container .mejs-track-artwork {
  width: 95px;
  height: 95px;
}

@media only screen and (min-width: 600px) {
  .cue-tracks {
    max-height: 420px;
  }
}

.cue-playlist .cue-tracks {
  box-shadow: none;
  border-radius: 0 0 5px 5px;
}

.cue-playlist .cue-track {
  border-color: #e6e6e6;
}

.cue-playlist .cue-track .cue-track-details .cue-track-title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
}

.cue-playlist .cue-track .cue-track-details .cue-track-artist {
  margin-top: 5px;
  text-transform: uppercase;
  color: rgba(129, 129, 129, 0.8);
  letter-spacing: 0.2em;
}

.cue-playlist .cue-track .cue-track-length {
  font-size: 12px;
  letter-spacing: 0.2em;
}

.cue-skin-default.mejs-container .mejs-controls .mejs-playpause-button {
  top: 42px;
  left: 42px;
  box-shadow: none !important;
  background: rgba(255, 255, 255, 0.6);
}

.cue-skin-default.mejs-container .mejs-controls .mejs-playpause-button.mejs-play button,
.cue-skin-default.mejs-container .mejs-controls .mejs-playpause-button.mejs-button button {
  border-color: transparent #ff5500;
}

.cue-skin-default.mejs-container .mejs-controls .mejs-playpause-button.mejs-play button {
  border-width: 7px 0 7px 12px;
  margin: 18px 15px 15px 21px;
}

.cue-skin-default.mejs-container .mejs-controls .mejs-playpause-button button {
  width: 10px;
  height: 12px;
}

.cue-skin-default.mejs-container .mejs-controls .mejs-playpause-button.mejs-pause button {
  border-width: 0 4px;
  margin: 19px 20px;
}

.cue-skin-default.mejs-container .mejs-track-details .mejs-track-artist {
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.cue-skin-default.mejs-container .mejs-track-details .mejs-track-title {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.cue-playlist .mejs-horizontal-volume-total {
  background: rgba(255, 255, 255, 0.15);
}

.cue-playlist .cue-track .cue-track-details {
  padding: 14px 20px;
}

.cue-playlist.max-width-380 .cue-skin-default.mejs-container .mejs-controls .mejs-playpause-button.mejs-play button {
  margin: 28px 25px 25px 31px;
}

.cue-playlist.max-width-380 .cue-skin-default.mejs-container .mejs-controls .mejs-playpause-button button {
  margin: 29px 31px;
}

/*
** Vertical Margins and Paddings
*/
/*same top and bottom paddings for sections*/
.section_padding_0 > [class*="container"] {
  padding: 0;
}

.section_padding_15 > [class*="container"] {
  padding-top: 15px;
  padding-bottom: 15px;
}

.section_padding_25 > [class*="container"] {
  padding-top: 25px;
  padding-bottom: 25px;
}

.section_padding_50 > [class*="container"] {
  padding-top: 50px;
  padding-bottom: 50px;
}

.section_padding_75 > [class*="container"] {
  padding-top: 75px;
  padding-bottom: 75px;
}

.section_padding_100 > [class*="container"] {
  padding-top: 100px;
  padding-bottom: 100px;
}

/*top paddings for sections*/
.section_padding_top_0 > [class*="container"] {
  padding-top: 0;
}

.section_padding_top_5 > [class*="container"] {
  padding-top: 5px;
}

.section_padding_top_10 > [class*="container"] {
  padding-top: 10px;
}

.section_padding_top_15 > [class*="container"] {
  padding-top: 15px;
}

.section_padding_top_20 > [class*="container"] {
  padding-top: 20px;
}

.section_padding_top_25 > [class*="container"] {
  padding-top: 25px;
}

.section_padding_top_30 > [class*="container"] {
  padding-top: 30px;
}

.section_padding_top_40 > [class*="container"] {
  padding-top: 40px;
}

.section_padding_top_50 > [class*="container"] {
  padding-top: 50px;
}

.section_padding_top_65 > [class*="container"] {
  padding-top: 65px;
}

.section_padding_top_75 > [class*="container"] {
  padding-top: 75px;
}

.section_padding_top_100 > [class*="container"] {
  padding-top: 100px;
}

.section_padding_top_130 > [class*="container"] {
  padding-top: 130px;
}

.section-padding-top-150 > [class*="container"] {
  padding-top: 150px;
}

/*bottom paddings for sections*/
.section_padding_bottom_0 > [class*="container"] {
  padding-bottom: 0;
}

.section_padding_bottom_5 > [class*="container"] {
  padding-bottom: 5px;
}

.section_padding_bottom_10 > [class*="container"] {
  padding-bottom: 10px;
}

.section_padding_bottom_15 > [class*="container"] {
  padding-bottom: 15px;
}

.section_padding_bottom_20 > [class*="container"] {
  padding-bottom: 20px;
}

.section_padding_bottom_25 > [class*="container"] {
  padding-bottom: 25px;
}

.section_padding_bottom_30 > [class*="container"] {
  padding-bottom: 30px;
}

.section_padding_bottom_40 > [class*="container"] {
  padding-bottom: 40px;
}

.section_padding_bottom_50 > [class*="container"] {
  padding-bottom: 50px;
}

.section_padding_bottom_65 > [class*="container"] {
  padding-bottom: 65px;
}

.section_padding_bottom_75 > [class*="container"] {
  padding-bottom: 75px;
}

.section_padding_bottom_100 > [class*="container"] {
  padding-bottom: 100px;
}

.section-padding-bottom-130 > [class*="container"] {
  padding-bottom: 130px;
}

.section_padding_bottom_150 > [class*="container"] {
  padding-bottom: 150px;
}

@media (max-width: 1200px) {
  .section_padding_100 > [class*="container"],
  .section_padding_top_100 > [class*="container"],
  .section_padding_top_130 > [class*="container"],
  .section-padding-top-150 > [class*="container"] {
    padding-top: 75px;
  }

  .section_padding_100 > [class*="container"],
  .section_padding_bottom_100 > [class*="container"],
  .section-padding-bottom-130 > [class*="container"],
  .section_padding_bottom_150 > [class*="container"] {
    padding-bottom: 75px;
  }
}

/* [class*="col-"] {
	margin-top: 10px;
	margin-bottom: 10px;
} */

header [class*="col-"],
.page_topline [class*="col-"] {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .columns_margin-0 [class*="col-"] {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.columns_padding_1 [class*="col-"] {
  margin-top: 0;
  margin-bottom: 1px;
}

.columns_padding_2 [class*="col-"] {
  margin-top: 1px;
  margin-bottom: 1px;
}

.columns_padding_5 [class*="col-"] {
  margin-top: 5px;
  margin-bottom: 5px;
}

.columns_padding_10 [class*="col-"] {
  margin-top: 10px;
  margin-bottom: 10px;
}

.columns_padding_1 [class*="col-"] {
  padding-left: 0;
  padding-right: 1px;
}

.columns_padding_2 [class*="col-"] {
  padding-left: 1px;
  padding-right: 1px;
}

.columns_padding_5 [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.columns_padding_10 [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.columns_padding_0 [class*="col-"] {
  padding: 0;
}

.columns_padding_0 > .container,
.columns_padding_1 > .container,
.columns_padding_2 > .container {
  padding-left: 30px;
  padding-right: 30px;
}

.columns_padding_5 > .container {
  padding-left: 25px;
  padding-right: 25px;
}

.columns_padding_10 > .container {
  padding-left: 20px;
  padding-right: 20px;
}

.columns_padding_10 > .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.columns_padding_0 > .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.row.columns_padding_0,
.columns_padding_0 .row {
  margin-left: 0;
  margin-right: 0;
}

.row.columns_padding_0[class*="display_table"],
.columns_padding_0 .row[class*="display_table"] {
  margin-right: -15px;
  margin-left: -15px;
  min-width: calc(100% + 30px);
}

.row.columns_padding_1,
.columns_padding_1 .row {
  margin-left: 0;
  margin-right: -1px;
}

.row.columns_padding_2,
.columns_padding_2 .row {
  margin-left: -1px;
  margin-right: -1px;
}

.row.columns_padding_5,
.columns_padding_5 .row {
  margin-left: -5px;
  margin-right: -5px;
}

.row.columns_padding_10,
.columns_padding_10 .row {
  margin-left: -10px;
  margin-right: -10px;
}

@media (min-width: 992px) {
  .columns-padding-25 > [class*="container"] > .row,
  .row.columns-padding-25 {
    margin-right: -25px;
    margin-left: -25px;
  }

  .columns-padding-25 > [class*="container"] > .row > [class*="col-"],
  .row.columns-padding-25 > [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px;
  }

  .columns_padding_50 > [class*="container"] > .row {
    margin-right: -50px;
    margin-left: -50px;
  }

  .columns_padding_50 > [class*="container"] > .row > [class*="col-"] {
    padding-left: 50px;
    padding-right: 50px;
  }

  .columns_padding_80 > [class*="container"] > .row {
    margin-right: -50px;
    margin-left: -50px;
  }

  .columns_padding_80 > [class*="container"] > .row > [class*="col-"] {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1200px) {
  .columns_padding_80 > [class*="container"] > .row {
    margin-right: -80px;
    margin-left: -80px;
  }

  .columns_padding_80 > [class*="container"] > .row > [class*="col-"] {
    padding-left: 80px;
    padding-right: 80px;
  }
}

/*top and bottom margins for columns inside sections and rows */
.columns_margin_top_0 [class*="col-"],
.row.columns_margin_top_0 [class*="col-"] {
  margin-top: 0;
}

.columns_margin_top_5 [class*="col-"],
.row.columns_margin_top_5 [class*="col-"] {
  margin-top: 5px;
}

.columns_margin_top_10 [class*="col-"],
.row.columns_margin_top_10 [class*="col-"] {
  margin-top: 10px;
}

.columns_margin_top_15 [class*="col-"],
.row.columns_margin_top_15 [class*="col-"] {
  margin-top: 15px;
}

.columns_margin_top_20 [class*="col-"],
.row.columns_margin_top_20 [class*="col-"] {
  margin-top: 20px;
}

.columns_margin_top_30 [class*="col-"],
.row.columns_margin_top_30 [class*="col-"] {
  margin-top: 30px;
}

.columns_margin_top_60 [class*="col-"],
.row.columns_margin_top_60 [class*="col-"] {
  margin-top: 60px;
}

.columns_margin_bottom_5 [class*="col-"],
.row.columns_margin_bottom_5 [class*="col-"] {
  margin-bottom: 5px;
}

.columns_margin_bottom_10 [class*="col-"],
.row.columns_margin_bottom_10 [class*="col-"] {
  margin-bottom: 10px;
}

.columns_margin_bottom_15 [class*="col-"],
.row.columns_margin_bottom_15 [class*="col-"] {
  margin-bottom: 15px;
}

.columns_margin_bottom_20 [class*="col-"],
.row.columns_margin_bottom_20 [class*="col-"] {
  margin-bottom: 20px;
}

.columns_margin_bottom_30 [class*="col-"],
.row.columns_margin_bottom_30 [class*="col-"] {
  margin-bottom: 30px;
}

.columns_margin_bottom_40 [class*="col-"],
.row.columns_margin_bottom_40 [class*="col-"] {
  margin-bottom: 40px;
}

.columns_margin_bottom_0 [class*="col-"],
.row.columns_margin_bottom_0 [class*="col-"] {
  margin-bottom: 0;
}

/* vertical alignmetn in columns */
.table_section .container {
  height: 100%;
}

.table_section .row {
  min-width: calc(100% + 30px);
  height: 100%;
}

@media (max-width: 767px) {
  aside {
    margin-top: 60px !important;
  }
}

@media (min-width: 768px) {
  .table_section.table_section_sm .row {
    display: table;
  }

  .table_section.table_section_sm > [class*="container"] > .row > [class*="col-"] {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}

@media (min-width: 992px) {
  .table_section.table_section_md .row {
    display: table;
  }

  .table_section.table_section_md > [class*="container"] > .row > [class*="col-"] {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}

@media screen and (min-width: 1200px) {
  .table_section.table_section_lg .row {
    display: table;
  }

  .table_section.table_section_lg > [class*="container"] > .row > [class*="col-"] {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}

@media screen and (min-width: 992px) {
  /* overlap featured person */
  .top-overlap {
    margin-top: -100px;
    max-width: 125%;
    margin-left: -25%;
  }

  .top-overlap-small {
    margin-top: -40px;
    max-width: 140%;
    margin-right: -20%;
    margin-left: -20%;
  }
}

@media (min-width: 1200px) {
  .top-overlap {
    margin-top: -180px;
  }
}

.section_full_height {
  min-height: 100vh;
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-row > [class*='col-'] {
  min-width: 300px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-row > [class*='col-'] > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .flex-row > [class*='col-'].col-md-6 {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    width: 50%;
  }
}

@media (max-width: 991px) {
  .flex-row > [class*='col-'].col-sm-12 {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    width: 100%;
  }
}

.flex-row.flex-row-vertical-centered > [class*='col-'] > * {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .container-fluid {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 1600px) {
  .container-fluid {
    padding-right: 80px;
    padding-left: 80px;
  }
}

.fluid_padding_0 .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}

/*
** Common Sections Styles
*/
/*parallax*/
.image_cover,
.background_cover,
.parallax {
  background-size: cover;
}

.background_cover {
  background-position: center;
}

.image_cover {
  display: block;
  position: absolute;
  width: 50%;
  z-index: 2;
  top: 0;
  bottom: 0;
  background-position: 50% 50%;
}

.image_cover > img {
  visibility: hidden;
}

.image_cover > a {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.image_cover.image_cover_right {
  right: 0;
}

@media (max-width: 991px) {
  .image_cover {
    height: 0;
    padding-bottom: 66%;
    margin-bottom: -30px;
    width: 100%;
    position: relative;
  }
}

.image_cover + [class*='container'] [class*='col-'] > img,
.image_cover + [class*='container'] [class*='col-'] > a:after,
.image_cover + [class*='container'] [class*='col-'] > a > img {
  display: none;
}

@media (min-width: 992px) {
  .half_section.columns_padding_80 > [class*="container"] > .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.half_section .image_cover_left + .container .col-md-6 {
  padding-right: 0;
}

.half_section .image_cover_right + .container .col-md-6 {
  padding-left: 0;
}

.parallax {
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
  position: relative;
}

@media (max-width: 767px) {
  .parallax {
    background-size: auto auto;
  }
}

.parallax.ls:after {
  background-color: #ffffff;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=92)";
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.parallax.ls.ms:after {
  background-color: #f5f5f5;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=88)";
  filter: alpha(opacity=80);
  opacity: 0.8;
}

.parallax.cs:after {
  background-color: #ff5500;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.parallax.cs.main_color2:after {
  background-color: #f0cd45;
}

.parallax.cs.main_color3:after {
  background-color: #e15b59;
}

.cs.gradient.color_overlay:after {
  background: linear-gradient(-90deg, #f0cd45, #ff5500);
}

.parallax.ds:after {
  background-color: #323232;
  opacity: 0.8;
}

.background_cover.ds:after {
  background-color: #323232;
  opacity: 0.9;
}

.parallax.ds.ms:after {
  background-color: #2a2a2a;
  opacity: 0.9;
}

.parallax.ds.black:after {
  background-color: #000;
  opacity: 0.6;
}

.texture_bg {
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
  position: relative;
}

.muted_section:before {
  background-color: rgba(50, 50, 50, 0.1);
  opacity: 1;
}

.parallax > *,
.muted_section > *,
.radial_gradient > *,
.gradient > *,
.vertical_gradient > *,
.darken_gradient > *,
.diagonal_section > *,
.color_overlay > * {
  z-index: 4;
  position: relative;
}

.radial_gradient {
  position: relative;
  overflow: hidden;
}

.gradient,
.muted_section,
.vertical_gradient,
.darken_gradient,
.color_overlay {
  position: relative;
}

.muted_section:before,
.muted_section:after,
.parallax:before,
.parallax:after,
.gradient:before,
.gradient:after,
.vertical_gradient:before,
.vertical_gradient:after,
.darken_gradient:before,
.color_overlay:after,
.darken_gradient:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

.muted_section:before,
.muted_section:after,
.parallax:before,
.parallax:after,
.background_cover:after {
  opacity: 0.8;
}

.parallax.clear_parallax:before, .parallax.clear_parallax:after {
  display: none;
}

.with_top_border,
.with_bottom_border,
.with_top_border_container .container,
.with_bottom_border_container .container {
  position: relative;
}

.with_top_border:before,
.with_bottom_border:after,
.with_top_border_container .container:before,
.with_bottom_border_container .container:after {
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  left: 0;
  right: 0;
  top: 0;
  background-color: #e6e6e6;
  z-index: 1;
}

.ds .with_top_border:before, .ds
.with_bottom_border:after, .ds
.with_top_border_container .container:before, .ds
.with_bottom_border_container .container:after {
  background-color: rgba(152, 152, 152, 0.2);
}

.ds.with_top_border:before, .ds.with_bottom_border:after,
.ds.with_top_border_container .container:before,
.ds.with_bottom_border_container .container:after {
  background-color: rgba(152, 152, 152, 0.2);
}

.with_top_border_container .container:before,
.with_bottom_border_container .container:after {
  right: 15px;
  left: 15px;
}

.with_bottom_border:after,
.with_bottom_border_container .container:after,
.with_bottom_border_two_colors:after {
  top: auto;
  bottom: 0;
}

.with_top_color_border:before {
  content: "";
  display: block;
  height: 4px;
  background-image: linear-gradient(to right, #ff5500 45%, #f0cd45 55%);
}

.bg_image,
.bg_image:after {
  background-image: url('../img/background_section.png');
}

/* Hero text in sections */
h2.section_header {
  margin-bottom: 30px;
  font-size: 40px;
  position: relative;
  word-wrap: break-word;
  line-height: 0.9;
  text-transform: uppercase;
  font-weight: 400;
}

h2.section_header.small,
h2.section_header .small {
  display: block;
  font-size: 16px;
}

h4 + h2.section_header,
h5 + h2.section_header {
  margin-top: -15px;
}

h4 + h2.section_header.small,
h5 + h2.section_header.small {
  margin-top: -10px;
}

h6 + h2.section_header {
  margin-top: -10px;
}

h2.section_header + .fontsize_20 {
  margin-bottom: 30px;
}

/*page preloader*/

/* animated elements */

/* boxed layout */
#box_wrapper, #canvas {
  overflow: hidden;
  position: relative;
}

#box_wrapper > section,
#box_wrapper > div > section {
  clear: both;
}

#canvas.boxed {
  padding: 1px 0;
}

body.boxed {
  position: static;
  width: auto;
  height: auto;
}

.boxed .container,
.boxed .container-fluid,
.boxed .container-left-half,
.boxed .container-right-half {
  max-width: 100%;
}

.boxed .container,
.boxed .container-left-half,
.boxed .container-right-half {
  padding-left: 25px;
  padding-right: 25px;
}

@media (max-width: 500px) {
  .boxed .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1400px) {
  .boxed #box_wrapper.container {
    width: 1370px;
  }
}

@media (min-width: 1590px) {
  .boxed #box_wrapper.container {
    width: 1560px;
  }
}

.boxed #box_wrapper.container {
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

/* full height for 404 page */
#box_wrapper, #canvas {
  min-height: 100vh;
}

#box_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section_404 {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

/*
**utilites
*/
/*
**Helper Utilites
*/
/* layout */
.clear, .clearfix {
  clear: both;
}

@media (min-width: 768px) {
  .alignleft {
    float: left;
    margin: 5px 30px 15px 0;
    max-width: 50%;
  }

  .alignright {
    float: right;
    margin: 5px 0 15px 30px;
    max-width: 50%;
  }

  .alignright.one-third,
  .alignleft.one-third {
    max-width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  .col-sm-12 .alignleft {
    margin-right: 50px;
    margin-bottom: 40px;
  }

  .col-sm-12 .alignright {
    margin-left: 50px;
    margin-bottom: 40px;
  }
}

.round {
  border-radius: 50%;
}

.rounded {
  border-radius: 5px;
}

.top-rounded {
  border-radius: 5px 5px 0 0;
}

.bottom-rounded {
  border-radius: 0 0 5px 5px;
}

.overflow-hidden {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.inline-block {
  display: inline-block;
  max-width: 100%;
}

.display-block {
  display: block;
}

.divided-content > * {
  display: inline-block;
}

.divided-content > *:before,
.divided-content > *:last-child:after {
  content: "";
  width: 1px;
  height: 8px;
  background-color: rgba(129, 129, 129, 0.6);
  display: inline-block;
  margin-right: 14px;
  margin-left: 10px;
  color: #818181;
}

.divided-content > *:first-child:before {
  margin-left: 0;
  display: none;
}

.divided-content > *:last-child:after {
  margin-right: 0;
  display: none;
}

.divided-content.outside-dividers > *:first-child:before,
.divided-content.outside-dividers > *:last-child:after {
  display: inline-block;
}

.with-padding {
  padding: 20px;
}

@media (min-width: 400px) {
  .with-padding {
    padding: 35px;
  }
}

@media (min-width: 400px) {
  .with-padding.small_padding {
    padding: 25px;
  }
}

@media (min-width: 1200px) {
  .with-padding.big-padding {
    padding: 55px;
  }
}

.with-background {
  background-color: #f5f5f5;
}

.with-background .muted_background {
  background-color: #ffffff;
}

.muted_background {
  background-color: #f2f2f2;
}

.with-border {
  border: 1px solid #e6e6e6;
}

.with_shadow {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.no_top_border {
  border-top: none;
}

.no_bottom_border {
  border-bottom: none;
}

.bottom-color-border {
  border-bottom: 3px solid #ff5500;
}

.bottom-color-border.rounded {
  position: relative;
  border-bottom: none;
  overflow: hidden;
  z-index: 1;
}

.bottom-color-border.rounded:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  display: block;
  background-color: #ff5500;
}

.bottom-border {
  position: relative;
}

.bottom-border:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
}

.before_cover,
.after_cover {
  overflow: hidden;
}

.before_cover,
.after_cover,
.before_cover > *,
.after_cover > * {
  position: relative;
  z-index: 2;
}

.before_cover:before,
.after_cover:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.top-corner {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;
}

.big {
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
}

.numbered {
  position: relative;
  z-index: 1;
}

.numbered .number {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 2.5em;
  font-weight: 700;
  opacity: 0.1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

/* borders, colors and backgrouns */
.response,
.highlight,
.highlight1 {
  color: #ff5500;
  border-color: #ff5500;
}

.highlightdark {
  color: #323232;
  border-color: #323232;
}

.highlight2 {
  color: #f0cd45;
  border-color: #f0cd45;
}

.highlight3 {
  color: #007ebd;
  border-color: #007ebd;
}

.red, .required {
  color: #fa5c5d;
}

.lightgrey {
  color: #bababa;
}

.black {
  color: #2a2a2a;
  border-color: #2a2a2a;
}

.grey {
  color: #323232;
  border-color: #323232;
}

.light {
  color: #ffffff;
}

.fontcolor {
  color: #818181;
}

.greylinks a {
  color: #818181;
}

.greylinks a.social-icon {
  color: rgba(129, 129, 129, 0.6);
}

.greylinks a.social-icon:hover, .greylinks a.social-icon:focus, .greylinks a.social-icon:active {
  color: #ff5500;
}

.darklinks a {
  color: #323232;
}

.colorlinks a {
  color: #f0cd45;
}

.colorlinks a:hover {
  color: #ff5500;
}

.underlined-links a {
  text-decoration: underline;
}

.thin {
  font-weight: 100;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.weight-black {
  font-weight: 900;
}

.success_color {
  color: #9fc201;
}

.info_color {
  color: #19bcdb;
}

.warning_color {
  color: #f4b803;
}

.danger_color {
  color: #ec5c42;
}

.main_bg_color,
.main_bg_color2,
.main_bg_color3,
.dark_bg_color,
.darkblue_bg_color,
.darkgrey_bg_color,
.darkgrey_bg.bg_teaser,
.success_bg_color,
.info_bg_color,
.warning_bg_color,
.danger_bg_color,
.danger_bg_color,
.transp_gradient_bg,
.transp_darkgrey_bg {
  color: #ffffff;
}

.main_bg_color h1, .main_bg_color h2, .main_bg_color h3, .main_bg_color h4, .main_bg_color h5, .main_bg_color h6,
.main_bg_color2 h1,
.main_bg_color2 h2,
.main_bg_color2 h3,
.main_bg_color2 h4,
.main_bg_color2 h5,
.main_bg_color2 h6,
.main_bg_color3 h1,
.main_bg_color3 h2,
.main_bg_color3 h3,
.main_bg_color3 h4,
.main_bg_color3 h5,
.main_bg_color3 h6,
.dark_bg_color h1,
.dark_bg_color h2,
.dark_bg_color h3,
.dark_bg_color h4,
.dark_bg_color h5,
.dark_bg_color h6,
.darkblue_bg_color h1,
.darkblue_bg_color h2,
.darkblue_bg_color h3,
.darkblue_bg_color h4,
.darkblue_bg_color h5,
.darkblue_bg_color h6,
.darkgrey_bg_color h1,
.darkgrey_bg_color h2,
.darkgrey_bg_color h3,
.darkgrey_bg_color h4,
.darkgrey_bg_color h5,
.darkgrey_bg_color h6,
.darkgrey_bg.bg_teaser h1,
.darkgrey_bg.bg_teaser h2,
.darkgrey_bg.bg_teaser h3,
.darkgrey_bg.bg_teaser h4,
.darkgrey_bg.bg_teaser h5,
.darkgrey_bg.bg_teaser h6,
.success_bg_color h1,
.success_bg_color h2,
.success_bg_color h3,
.success_bg_color h4,
.success_bg_color h5,
.success_bg_color h6,
.info_bg_color h1,
.info_bg_color h2,
.info_bg_color h3,
.info_bg_color h4,
.info_bg_color h5,
.info_bg_color h6,
.warning_bg_color h1,
.warning_bg_color h2,
.warning_bg_color h3,
.warning_bg_color h4,
.warning_bg_color h5,
.warning_bg_color h6,
.danger_bg_color h1,
.danger_bg_color h2,
.danger_bg_color h3,
.danger_bg_color h4,
.danger_bg_color h5,
.danger_bg_color h6,
.danger_bg_color h1,
.danger_bg_color h2,
.danger_bg_color h3,
.danger_bg_color h4,
.danger_bg_color h5,
.danger_bg_color h6,
.transp_gradient_bg h1,
.transp_gradient_bg h2,
.transp_gradient_bg h3,
.transp_gradient_bg h4,
.transp_gradient_bg h5,
.transp_gradient_bg h6,
.transp_darkgrey_bg h1,
.transp_darkgrey_bg h2,
.transp_darkgrey_bg h3,
.transp_darkgrey_bg h4,
.transp_darkgrey_bg h5,
.transp_darkgrey_bg h6 {
  color: #ffffff;
}

.main_bg_color {
  background-color: #ff5500;
}

.main_bg_color_mutted {
  background-color: #b66466;
}

.main_bg_color2 {
  background-color: #f0cd45;
}

.dark_bg_color {
  background-color: #2a2a2a;
}

.darkblue_bg_color {
  color: #ffffff;
  background-color: #1c242f;
}

.darkgrey_bg_color {
  color: #ffffff;
  background-color: #323232;
}

.success_bg_color {
  background-color: #9fc201;
}

.info_bg_color {
  background-color: #19bcdb;
}

.warning_bg_color {
  background-color: #f4b803;
}

.danger_bg_color {
  background-color: #ec5c42;
}

.light_bg_color {
  background-color: #ffffff;
}

.gradient_bg {
  background: linear-gradient(to right, #ff5500, #f0cd45);
}

.transp_gradient_bg {
  background: linear-gradient(to right, rgba(224, 49, 47, 0.8), rgba(240, 205, 69, 0.8));
}

.transp_gradient_bg.cs {
  background-color: transparent;
}

.transp_darkgrey_bg {
  background-color: rgba(50, 50, 50, 0.8);
}

.theme_bg {
  background-color: #ffffff;
}

/*dividers, margins and paddings*/
.divider_20 {
  margin: 20px 0;
}

.divider_30 {
  margin: 30px 0;
}

.divider_40 {
  margin: 40px 0;
}

.text-center hr[class*="divider_"],
.text-center hr[class*="_divider"] {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.text-center hr[class*="divider_"]:before,
.text-center hr[class*="_divider"]:before {
  margin-left: -35px;
}

.margin-0 {
  margin: 0 !important;
}

.topmargin-0 {
  margin-top: 0 !important;
}

.topmargin_1 {
  margin-top: 1px;
}

.topmargin_5 {
  margin-top: 5px;
}

.topmargin_10 {
  margin-top: 10px;
}

.topmargin_15 {
  margin-top: 15px;
}

.topmargin_20 {
  margin-top: 20px;
}

.top-margin-25 {
  margin-top: 25px;
}

.topmargin_30 {
  margin-top: 30px;
}

.topmargin_35 {
  margin-top: 35px;
}

.top-margin-40 {
  margin-top: 40px;
}

.topmargin_45 {
  margin-top: 45px;
}

.topmargin_50 {
  margin-top: 50px;
}

.topmargin_60 {
  margin-top: 60px;
}

.topmargin_80 {
  margin-top: 80px;
}

.bottom-margin-0 {
  margin-bottom: 0 !important;
}

.bottommargin_5 {
  margin-bottom: 5px;
}

.bottommargin_10 {
  margin-bottom: 10px;
}

.bottommargin_20 {
  margin-bottom: 20px;
}

.bottommargin_25 {
  margin-bottom: 25px;
}

.bottommargin_30 {
  margin-bottom: 30px;
}

.bottom-margin-40 {
  margin-bottom: 40px;
}

.bottommargin_50 {
  margin-bottom: 50px;
}

.bottommargin_60 {
  margin-bottom: 60px;
}

.leftmargin_10 {
  margin-left: 10px;
}

.leftmargin_20 {
  margin-left: 20px;
}

.leftmargin_30 {
  margin-left: 30px;
}

.leftmargin_40 {
  margin-left: 40px;
}

.leftmargin_50 {
  margin-left: 50px;
}

.leftmargin_60 {
  margin-left: 60px;
}

.rightmargin_10 {
  margin-right: 10px;
}

.rightmargin_20 {
  margin-right: 20px;
}

.rightmargin_30 {
  margin-right: 30px;
}

.rightmargin_40 {
  margin-right: 40px;
}

.rightmargin_50 {
  margin-right: 50px;
}

.rightmargin_60 {
  margin-right: 60px;
}

.padding_0 {
  padding: 0;
}

.padding_10 {
  padding: 10px;
}

.padding_20 {
  padding: 20px;
}

.padding_30 {
  padding: 30px;
}

.padding_40 {
  padding: 40px;
}

.toppadding_10 {
  padding-top: 10px;
}

.toppadding_20 {
  padding-top: 20px;
}

.toppadding_30 {
  padding-top: 30px;
}

.toppadding_40 {
  padding-top: 40px;
}

.toppadding_50 {
  padding-top: 50px;
}

.toppadding_60 {
  padding-top: 60px;
}

.bottompadding_10 {
  padding-bottom: 10px;
}

.bottompadding_20 {
  padding-bottom: 20px;
}

.bottompadding_30 {
  padding-bottom: 30px;
}

.bottompadding_40 {
  padding-bottom: 40px;
}

.bottompadding_50 {
  padding-bottom: 50px;
}

.bottompadding_60 {
  padding-bottom: 60px;
}

.leftpadding_10 {
  padding-left: 10px;
}

.leftpadding_20 {
  padding-left: 20px;
}

.leftpadding_30 {
  padding-left: 30px;
}

.leftpadding_40 {
  padding-left: 40px;
}

.leftpadding_50 {
  padding-left: 50px;
}

.leftpadding_60 {
  padding-left: 60px;
}

.rightpadding_5 {
  padding-right: 5px;
}

.rightpadding_10 {
  padding-right: 10px;
}

.rightpadding_20 {
  padding-right: 20px;
}

.rightpadding_25 {
  padding-right: 25px;
}

.rightpadding_30 {
  padding-right: 30px;
}

.rightpadding_40 {
  padding-right: 40px;
}

.rightpadding_50 {
  padding-right: 50px;
}

.rightpadding_60 {
  padding-right: 60px;
}

.fontsize_12 {
  font-size: 12px;
}

.fontsize_14 {
  font-size: 14px;
}

.fontsize_16 {
  font-size: 16px;
}

.fontsize_18 {
  font-size: 18px;
}

.fontsize_20 {
  font-size: 20px;
}

.fontsize_24 {
  font-size: 24px;
}

.fontsize_26 {
  font-size: 26px;
}

.fontsize_28 {
  font-size: 28px;
}

.fontsize_30 {
  font-size: 30px;
}

.fontsize_32 {
  font-size: 32px;
}

.playfair {
  font-family: 'Playfair Display', serif;
}

.lineheight_thin {
  line-height: 24px;
}

/*alignment*/
.display_table {
  display: table;
  min-width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

.display_table_cell {
  display: table-cell;
  vertical-align: middle;
  float: none;
  overflow: hidden;
  zoom: 1;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .pull-sm-right {
    float: right;
  }

  .pull-sm-left {
    float: left;
  }

  .text-sm-right {
    text-align: right;
  }

  .text-sm-left {
    text-align: left;
  }

  .text-sm-center {
    text-align: center;
  }

  .display_table_sm {
    display: table;
    min-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
  }

  .display_table_cell_sm {
    display: table-cell;
    vertical-align: middle;
    float: none;
    overflow: hidden;
    zoom: 1;
    box-sizing: border-box;
  }
}

@media (min-width: 992px) {
  .pull-md-right {
    float: right;
  }

  .pull-md-left {
    float: left;
  }

  .text-md-right {
    text-align: right;
  }

  .text-md-left {
    text-align: left;
  }

  .text-md-center {
    text-align: center;
  }

  .display_inline_md {
    display: inline;
  }

  .display_table_md {
    display: table;
    min-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
  }

  .display_table_cell_md {
    display: table-cell;
    vertical-align: middle;
    float: none;
    zoom: 1;
    box-sizing: border-box;
  }
}

@media (min-width: 1200px) {
  .pull-lg-right {
    float: right;
  }

  .pull-lg-left {
    float: left;
  }

  .text-lg-right {
    text-align: right;
  }

  .text-lg-left {
    text-align: left;
  }

  .text-lg-center {
    text-align: center;
  }

  .display_table_lg {
    display: table;
    min-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
  }

  .display_table_cell_lg {
    display: table-cell;
    vertical-align: middle;
    float: none;
    overflow: hidden;
    zoom: 1;
    box-sizing: border-box;
  }
}

@media (max-width: 767px) {
  .text-xs-right {
    text-align: right;
  }

  .text-xs-left {
    text-align: left;
  }

  .text-xs-center {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .hidden-xxs {
    display: none;
  }
}

.inline-dropdown {
  list-style: none;
  margin: 0;
}

.inline-dropdown > li {
  display: inline-block;
  margin: 0 -2px;
}

.cons-width {
  width: 1.2em;
}

.small-text {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.nowrap {
  white-space: nowrap;
}

.media.inline-block {
  display: inline-block;
}

.media.inline-block .media-body {
  width: auto;
}

.content-justify {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content-justify.content-margins > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

.content-justify.nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.inline-content {
  margin-right: -12px;
  margin-left: -12px;
}

.inline-content > * {
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
  margin-left: 12px;
  margin-bottom: 0;
}

.inline-content > *.media, .inline-content > *.media.inline-block {
  margin-top: 0;
  margin-bottom: 0;
}

.inline-content > *:last-child {
  margin-right: 12px;
}

.inline-content.vertical-margin > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .inline-content {
    margin-right: -14px;
    margin-left: -14px;
  }

  .inline-content > * {
    margin-left: 14px;
    margin-right: 14px;
  }
}

.item-meta .inline-content > * {
  margin-right: 10px;
  margin-left: 10px;
}

.display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.vertical-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-3lines-ellipsis {
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  font-size: 16px;
  height: 90px;
  -webkit-line-clamp: 3;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* media items - common side and vertical items */
.with-border .item-media,
.with-border .entry-thumbnail {
  margin: -1px;
  z-index: 2;
}

.with-border .item-media + header,
.with-border .entry-thumbnail + header {
  margin: 0 -1px;
}

.with-border.full-padding .item-media {
  margin: 0;
}

/* side and vertical items paddings */
.item-content.with_overlapped_button {
  position: relative;
  z-index: 3;
}

.item-content.with_overlapped_button .round_button {
  position: absolute;
  top: -30px;
  left: 50%;
  margin-left: -30px;
}

.side-item .row {
  margin: 0;
}

.side-item .row [class*="col-"] {
  padding: 0;
  margin: 0;
}

.side-item.side-sm .item-content {
  padding-top: 30px;
}

@media (min-width: 768px) {
  .side-item.side-sm .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .side-item.side-sm .row [class*="col-"] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .side-item.side-sm .row .item-content {
    padding-top: 0;
    padding-bottom: 10px;
  }
}

.side-item.side-md .item-content {
  padding-top: 30px;
}

@media (min-width: 992px) {
  .side-item.side-md .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .side-item.side-md .row [class*="col-"] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .side-item.side-md .row .top-rounded {
    border-radius: 5px 0 0 5px;
  }
}

/*-xs- side-item paddings and margins*/
@media (max-width: 767px) {
  .no-content-padding [class*='col-xs-'][class*='col-xs-pull'] .item-content,
  .full-padding [class*='col-xs-'][class*='col-xs-pull'] .item-content {
    padding-left: 0;
    padding-right: 30px;
  }

  .no-content-padding .col-xs-12 .item-content,
  .full-padding .col-xs-12 .item-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}

/*-sm- side-item paddings and margins*/
@media (min-width: 768px) {
  .no-content-padding [class*='col-sm-'] .item-content,
  .full-padding [class*='col-sm-'] .item-content {
    padding-left: 30px;
  }

  .no-content-padding [class*='col-sm-'][class*='col-sm-pull'] .item-content,
  .full-padding [class*='col-sm-'][class*='col-sm-pull'] .item-content {
    padding-left: 0;
    padding-right: 30px;
  }

  .no-content-padding .col-sm-12 .item-content,
  .full-padding .col-sm-12 .item-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}

/*-md- side-item paddings and margins*/
@media (min-width: 992px) {
  .no-content-padding [class*='col-md-'] .item-content,
  .full-padding [class*='col-md-'] .item-content {
    padding-left: 30px;
  }

  .no-content-padding [class*='col-md-'][class*='col-md-pull'] .item-content,
  .full-padding [class*='col-md-'][class*='col-md-pull'] .item-content {
    padding-left: 0;
    padding-right: 30px;
  }

  .no-content-padding .col-md-12 .item-content,
  .full-padding .col-md-12 .item-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}

/*-lg- side-item paddings and margins*/
@media (min-width: 1200px) {
  .no-content-padding [class*='col-lg-'] .item-content,
  .full-padding [class*='col-lg-'] .item-content {
    padding-left: 30px;
  }

  .no-content-padding [class*='col-lg-'][class*='col-lg-pull'] .item-content,
  .full-padding [class*='col-lg-'][class*='col-lg-pull'] .item-content {
    padding-left: 0;
    padding-right: 30px;
  }

  .no-content-padding .col-lg-12 .item-content,
  .full-padding .col-lg-12 .item-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}

.vertical-item.post .item-content {
  padding-top: 0;
}

.vertical-item.content-padding .item-content {
  padding: 30px 20px;
}

@media (min-width: 400px) {
  .vertical-item.content-padding .item-content {
    padding: 35px;
  }
}

@media (min-width: 1200px) {
  .vertical-item.big-padding .item-content {
    padding: 55px;
  }
}

@media (min-width: 1200px) {
  .vertical-item.big-vertical-padding .item-content {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}

.vertical-item.content-absolute {
  position: relative;
}

.vertical-item.content-absolute .item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 2;
}

@media (min-width: 400px) {
  .vertical-item.content-absolute .item-content {
    padding: 35px;
  }
}

@media (min-width: 1200px) {
  .vertical-item.content-absolute .item-content.big-padding {
    padding: 55px;
  }
}

.vertical-item.content-absolute .item-content.ds {
  background-color: rgba(50, 50, 50, 0.8);
}

.vertical-item.content-absolute.vertical-center .item-content {
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.vertical-item + .item-title {
  padding: 10px 30px;
}

.vertical-item + .item-title h2, .vertical-item + .item-title h3, .vertical-item + .item-title h4 {
  margin: 0;
}

.vertical-item.hover-entry-content .entry-content {
  padding-top: 20px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

.vertical-item.hover-entry-content .hover-hidden {
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out 0s;
}

.vertical-item.hover-entry-content:hover .entry-content {
  opacity: 1;
  max-height: 190px;
}

.vertical-item.hover-entry-content:hover .hover-hidden {
  opacity: 0;
}

.side-item.content-padding .item-content {
  padding: 25px 20px;
}

@media (min-width: 400px) {
  .side-item.content-padding .item-content {
    padding: 30px 35px;
  }
}

@media (min-width: 1200px) {
  .side-item.big-padding .item-content {
    padding-right: 55px;
  }
}

.content-padding footer {
  position: relative;
  padding: 18px 20px;
  border-top: 1px solid #e6e6e6;
}

@media (min-width: 400px) {
  .content-padding footer {
    padding: 18px 35px;
  }
}

@media (min-width: 1200px) {
  .content-padding.big-padding footer {
    padding: 18px 55px;
  }
}

@media (min-width: 1200px) {
  .content-padding.big-padding.side-item footer {
    padding: 18px 55px 18px 55px;
  }
}

/* item meta */
/* item media links */
.item-media {
  position: relative;
  overflow: hidden;
}

.item-media img {
  width: auto;
}

.item-media.with_icon {
  overflow: visible;
}

.item-media .post_icon {
  position: absolute;
  bottom: -30px;
  left: 50%;
  margin-left: -30px;
  width: 60px;
  border-radius: 50%;
  background-color: #ff5500;
}

.item-media .post_icon i {
  color: #ffffff;
  line-height: 60px;
}

.media-links {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.media-links div {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -25px;
  z-index: 4;
}

.media-links:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(29, 28, 33, 0.5);
  filter: alpha(opacity=0);
  opacity: 0;
  transition: all 0.2s linear;
}

.media-links.color-overlay:before {
  opacity: 1;
}

[class*="-item"]:hover .media-links.color-overlay:before {
  opacity: 0;
}

[class*="-item"]:hover .media-links:before {
  opacity: 1;
}

.media-links.no-overlay:before {
  display: none;
}

.media-links.inverse:before {
  opacity: 1;
}

[class*="-item"]:hover .media-links.inverse:before {
  opacity: 0;
}

.media-links a.abs-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

[class*="-item"]:hover .media-links a.abs-link {
  opacity: 1;
}

[class*="-item"] .links-wrap a,
[class*="-item"] .links-wrap > span {
  position: relative;
  width: 60px;
  height: 60px;
  line-height: 60px;
  top: -100px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 30px;
  display: inline-block;
  z-index: 5;
  font-size: 12px;
  text-align: center;
  filter: alpha(opacity=0);
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

[class*="-item"] .links-wrap a.p-view {
  color: #ff5500;
}

[class*="-item"] .links-wrap a.p-view:hover {
  color: #ffffff;
}

[class*="-item"] .links-wrap a.p-link {
  color: #ff5500;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
}

[class*="-item"] .links-wrap a.p-link:hover {
  color: #ffffff;
}

[class*="-item"] .links-wrap a:before {
  font-family: 'FontAwesome';
  content: "\f002";
}

[class*="-item"] .links-wrap a.p-view {
  top: 0;
  background-color: transparent;
}

[class*="-item"] .links-wrap a.p-view:before, [class*="-item"] .links-wrap a.p-view:after {
  position: absolute;
  content: "";
  background-color: #ff5500;
  left: 50%;
  top: 50%;
  opacity: 0;
  -webkit-transform: translate(-150%, -150%);
  transform: translate(-150%, -150%);
  transition: all 0.2s ease 0.2s;
}

[class*="-item"] .links-wrap a.p-view:before {
  width: 2px;
  height: 22px;
}

[class*="-item"] .links-wrap a.p-view:after {
  height: 2px;
  width: 22px;
}

/* item icons */
.item-icons {
  display: table;
  width: 100%;
  border-top: 1px solid rgba(128, 128, 128, 0.1);
}

.item-icons i {
  font-size: 14px;
}

.item-icons > div {
  padding: 24px 50px 24px;
  display: table-cell;
  width: 400px;
  white-space: nowrap;
}

@media (max-width: 1199px) {
  .item-icons > div {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.item-icons > div.text-right {
  padding-left: 0;
}

.item-icons [data-id] {
  display: none;
}

.media-links .bottom-links {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  top: auto;
}

.media-links .bottom-links a {
  padding: 1px 11px;
  background-color: #323232;
  display: inline-block;
  margin: 0 2px;
}

.media-links .bottom-links a:hover {
  background-color: #f0cd45;
}

@media (min-width: 1200px) {
  .media-links .bottom-links a {
    opacity: 0;
  }
}

[class*="-item"]:hover .links-wrap a,
[class*="-item"]:hover .links-wrap > span,
.opened-media .links-wrap a,
.opened-media .links-wrap > span {
  top: 0px;
  filter: alpha(opacity=100);
  opacity: 1;
}

[class*="-item"]:hover .links-wrap a.p-view:before, [class*="-item"]:hover .links-wrap a.p-view:after,
[class*="-item"]:hover .links-wrap > span.p-view:before,
[class*="-item"]:hover .links-wrap > span.p-view:after,
.opened-media .links-wrap a.p-view:before,
.opened-media .links-wrap a.p-view:after,
.opened-media .links-wrap > span.p-view:before,
.opened-media .links-wrap > span.p-view:after {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

[class*="-item"]:hover .media-links div,
.opened-media .media-links div {
  filter: alpha(opacity=100);
  opacity: 1;
}

@media (min-width: 1200px) {
  [class*="-item"]:hover .media-links .bottom-links a,
  .opened-media .media-links .bottom-links a {
    opacity: 1;
  }
}

/* side header layout */


/* sidebar scroll plugin */

/*
** Light Section - .ls
*/
.ls {
  background-color: #ffffff;
  color: #818181;
}

/*
** Template Specific Styles
*/
/* topline styles */

/* toplogo styles */


/* title section (breadcrumbs) styles */


/* template parallax sections */

/* footer styles */

/* copyrights styles */

/* google map  */

/*
** Intro Section Styles
*/

/*
** Main Menu styles
*/

/* Desktop menu - (min 992px) styles */


/* common paddings */
@media (min-width: 992px) {
  .sf-menu > li > a {
    margin-left: 2px;
    margin-right: 2px;
  }

  [class*="container"] .sf-menu > li:first-child > a {
    margin-left: 0;
  }

  [class*="container"] .sf-menu > li:last-child > a {
    margin-right: 0;
  }

  .bordered_items .sf-menu > li > a {
    margin: 0;
    padding: 25px;
  }
}

/* -md- paddings */

/* -lg- paddings */


/* hidden menu items */

/* logo in center of menu */


/* Mobile menu toggler */


/* Mobile Menu (max 991px) styles */

/* Mega Menu */

/*
**Subpages
*/

/*404*/

/* additional meta at the bottom of entry-title */

/*single post*/

/*comments*/

.tab-content .item-content button {
  min-width: 150px;
}

.tab-content .item-content .price {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .nav li {
    width: 49%;
    margin-left: 0;
  }

  .nav li a {
    width: auto;
    display: block;
    text-align: center;
    font-size: 14px;
    border-radius: 0;
  }

  .club-desc .tab-content {
    margin-top: 0;
  }

  .club-desc {
    margin-top: 20px;
  }

  .tab-content #tab0 .MuiGrid-item {
    max-width: 100%;
    flex-basis: 100%;
  }

  .club-profile-container {
    padding-top: 150px !important;
  }
}

@media (max-width: 499px) {
  .product-create-btn button {
    width: 100%;
  }

  .public-profile-sponsor-list button {
    margin-bottom: 20px;
    width: 100%;
  }

  .makeStyles-productImage-2 {
    max-width: 100% !important;
    max-height: 180px;
    height: unset !important;
  }
}


.product-description-profile {
  font-size: 90%;
}

.product-title:after {
  position: absolute;
  display: block;
  content: "";
  width: 10%;
  height: 2px;
  background: #f50 !important;
  margin-top: 10px;
}

.sponsor-item-default-margin {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

@media (min-width: 768px) {
  .public-profile-sponsor-list {
    margin: 15px 0 0 0;
  }

  .public-profile-sponsor-list .product-title {
    margin-top: 0px !important;
    margin-bottom: 18px !important;
  }

  .product-image-profile {
    flex-basis: 30% !important;
    max-width: 30% !important;
  }

  .product-description-profile {
    min-height: 40px;
  }

  .crowd-product {
    margin-top: -15px !important;
  }

  .public-profile-sponsor-list .product-button {
    min-width: 125px !important;
  }

  .club-description {
    margin-top: 50px;
  }
}

.modern-tabs-content #tab2 {
  width: 100%;
}

#userPage .modern-tabs {
  border-bottom: 2px solid #fafafa !important;
}

#userPage .modern-tabs li {
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 25%;
}

#userPage .modern-tabs li a {
  padding: 15px 10px !important;
  margin: 0 !important;
  background-color: #f1f1f1 !important;
  border-radius: 0 !important;
  border-top: 5px solid transparent;
  text-align: center;
  width: 100%;
}

#userPage #active {
  border-top: 5px solid #f50;
  background-color: #fff !important;
}

#userPage .modern-tabs li a:hover {
  border-top: 5px solid #c4c4c4;
  background-color: #e8e8e8 !important;
  color: #818181 !important;
}

#editUserProfileModal .profile-user-img {
  border-radius: 100% !important;
}

#editUserProfileModal .MuiContainer-maxWidthXs {
  max-width: none;
}

#editUserProfileModal #responsive-dialog-title {
  text-align: left;
}

#editUserProfileModal .MuiDialogTitle-root {
  padding: 15px 24px;
}

#editUserProfileModal .MuiDialogContent-root .MuiContainer-root {
  padding: 0 !important;
}

#editUserProfileModal .MuiDialogActions-root {
  padding: 8px 24px 20px 24px !important;
}

.clubTabPanel {
  width: 100%;
  background: #fbfbfb;
  display: flex;
}

.clubTabPanel ul {
  display: flex;
  flex-flow: column;
  align-content: space-evenly;
}

.clubTabPanel li {
  text-align: center;
}

.clubTabPanel .tab-content {
  width: 100%;
}

.club-profile-container .modern-tabs li a {
  padding: 15px 45px !important;
  width: 100%;
  margin: 0 !important;
  background-color: #f1f1f1 !important;
  border-radius: 0 !important;
  border-right: 5px solid transparent;
}

.club-profile-container .modern-tabs {
  border-bottom: none !important;
}

.club-profile-container #active, .modern-tabs li:hover {
  border-bottom: 0 !important;
}

.club-profile-container #active {
  border-right: 5px solid #f50;
  background-color: #fff !important;
}

.club-profile-container .modern-tabs li a:hover {
  border-right: 5px solid #c4c4c4;
  background-color: #e8e8e8 !important;
  color: #818181 !important;
}

.modern-tabs li a:focus {
  color: #818181 !important;
}

.club-profile-container .modern-tabs li {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

#qr-canvas {
  width: 100% !important;
  height: auto !important;
}

#memberShipTypeDropdown {
  min-width: 200px;
}

#memberShipForm .MuiGrid-grid-sm-2 {
  flex-basis: 30%;
  max-width: none;
}

#memberShipForm button {
  font-size: 12px;
  width: 100%;
}

button:disabled {
  background-color: #cccccc !important;
  color: #888888;
  cursor: not-allowed;
}

#membershipTab {
  background: transparent;
  box-shadow: none;
  border-radius: 5px;
  margin: 15px 0;
}

#membershipTableContainer {
  background: transparent;
}

#membershipTable table {
  margin: 0;
}

#membershipTable th {
  background: #f1f1f1;
  font-weight: bold;
  color: #444;
  text-align: center;
  font-size: .8vw;
  padding: 3px 10px;
}

#membershipTable td {
  text-align: center;
  font-size: .8vw;
  padding: 10px;
}

.btn-tertiary span {
  font-size: .8vw;
}

.paymentDate {
  width: auto !important;
}

.paymentDate input {
  text-align: left !important;
  font-size: .8vw;
}

.membershipStatus {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  align-items: center;
}

.membershipStatusActive, .membershipPaid {
  background-color: #77ce78;
}

.membershipPaidInPart, .membershipStatusInactive {
  background-color: #ffd369;
}

.membershipUnpaid {
  background-color: #ff7675;
}

#memberShipForm .MuiGrid-grid-sm-2 {
  flex-basis: 50% !important;;
  max-width: none !important;
}

#addMemberForm .MuiGrid-grid-sm-2, .club-profile-private .MuiGrid-grid-sm-4 {
  flex-basis: 50% !important;
  max-width: none !important;
}

.club-profile-private .MuiIconButton-root {
  margin-left: 5px;
  margin-top: 5px;
}

.club-profile-private .MuiTextField-root {
  width: 100%;
}

.club-profile-private .MuiDialog-paperWidthSm {
  min-height: auto !important;
}

.club-profile-private .MuiIconButton-root:disabled {
  cursor: default !important;
  pointer-events: all !important;
  opacity: .6;
  border: 2px solid #d7d6d6;
  background: transparent !important;
}

.club-profile-private .MuiIconButton-root {
  padding: 7px !important;
  border: 2px solid #cfcdcd;
}

#membershipAddMember .action-buttons-modal,
#membershipMore .action-buttons-modal {
  padding: 8px 24px 20px 24px !important;
}

#membershipMore .MuiDialogActions-root {
  display: block !important;
}

#membershipMore .MuiGrid-grid-sm-3 {
  flex-basis: 50% !important;
  max-width: none !important;
}


@media screen and (max-width: 1500px) {
  #membershipTable th,
  #membershipTable td,
  .paymentDate input,
  .btn-tertiary span {
    font-size: 12px;
  }
}

@media screen and (max-width: 992px) {
  #membershipTable th,
  #membershipTable td,
  .paymentDate input,
  .btn-tertiary span {
    font-size: 11px;
    line-height: 14px !important;
    padding: 12px 5px;
  }

  .clubTabPanel {
    display: block;
  }

  .club-profile-private .modern-tabs li a {
    border-right: 0;
    border-bottom: 5px solid transparent;
  }

  .club-profile-private #active {
    border-right: 0;
    border-bottom: 3px solid #f50 !important;
  }

  .club-profile-private .modern-tabs li a:hover {
    border-right: 0;
    border-bottom: 3px solid #c4c4c4;
  }
}